/*--------------------------------------*/
/*---------------General-----------------*/

:root {
  --white: #fff;
  --gray-text: #44474a;
  --dark-blue: rgb(43, 61, 125);
  --light-blue: rgb(45, 179, 244);
  --light-grey: rgba(75, 75, 75, 0.098);
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  font-size: 24px;
}

body {
  margin: 0;
  line-height: 1.4;
}

nav a,
#contact-me a {
  color: var(--light-blue);
  font-size: 1.1em;
  font-weight: 400;
}

nav a:visited {
  color: var(--light-blue);
}

p {
  color: var(--gray-text);
}

h2 {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.2em;
  color: var(--gray-text);
  text-align: center;
}

h3 {
  color: var(--light-blue);
}

/*--------------------------------------*/
/*---------------Header-----------------*/
#header-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

h1 {
  display: block;
  font-size: 1rem;
  font-weight: 200;
  color: var(--light-blue);
  opacity: 0.66;
  border: solid 0.5px var(--light-blue);
  padding: 10px;
  border-radius: 20px;
  position: absolute;
  top: 15px;
  left: 15px;
}

h1:hover {
  animation: h1Change 1.5s ease-in;
}

nav {
  color: var(--light-blue);
  opacity: 0.66;
}

#menu-item {
  display: none;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: all 0.2s;
  position: absolute;
  top: 20px;
  right: 20px;
}

#menu-item li {
  padding: 5px 0;
  font-size: 0.65rem;
  transition: all 0.4s;
}

#menu-item li:hover {
  transform: scale(1.01) translateY(-3px);
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.29);
}

/*--------------------------------------*/
/*----------------Home-----------------*/
#greeting {
  margin-top: 0.5em;
  min-height: 100vh;
  border-bottom: solid 0.5px var(--light-grey);
}

#greeting-body {
  display: flex;
  flex-direction: column;
}

#profile {
  max-width: 50%;
  height: auto;
  align-self: center;
  /* border: solid 0.25px var(--light-blue); */
  border-radius: 50%;
  margin: 120px auto 0;
  transition: all 0.2s ease-in;
  animation: imgFade 9s;
}

#greeting-text {
  margin-top: 50px;
}

#greeting-text p {
  margin: 10px;
  text-align: center;
  font-size: 0.7rem;
}

#hi {
  animation: hiSlide 3s;
}

#intro {
  animation: moveInBottom 6s;
}

.btn {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  padding: 0.5rem 1.2rem 0.3rem;
  border-bottom: solid 0.01rem rgba(45, 178, 244, 0.447);
  transition: all 0.8s;
  position: relative;
  margin: 70px auto;
  font-size: 0.7rem;
  color: black;
  animation: moveInBottom 8s;
}

.btn:hover {
  color: var(--light-blue);
  box-shadow: 0 0.03rem 0.05rem var(--light-blue);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 0.2rem 0.1rem rgba(0, 0, 0, 0.256);
  transition: 0.4s;
}

.btn:visited {
  color: var(--light-blue);
}

/*--------------------------------------*/
/*----------------Work-----------------*/

#work {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#work h2 {
  padding: 0;
  border-bottom: solid 0.5px var(--light-blue);
  margin-bottom: 4vh;
}

#workCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;
}

/*--------------------------------------*/
/*----------------About-----------------*/

#about-me {
  border-top: solid 0.25px var(--light-grey);
  border-bottom: solid 0.25px var(--light-grey);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 20px;
  min-height: 100vh;
}

#about-me h2 {
  padding: 0;
  /* font-size: 1.3rem; */
  margin-bottom: 50px;
  border-bottom: solid 0.5px var(--light-blue);
}

#about-me p {
  margin: 0 5% 40px;
  font-size: 0.6rem;
}

#tools {
  margin: 6vh 0 4vh;
  color: black;
  font-weight: 400;
  padding: 0;
  border-bottom: solid 0.5px var(--light-blue);
}

/*--------------------------------------*/
/*----------------Contact---------------*/

#contact-me {
  display: flex;
  flex-direction: column;
}

.bottom-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: right;
}

.social {
  display: flex;
}

.social span {
  margin-left: 30px;
  transition: all 0.4s;
}

.social span:hover {
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
}

footer {
  margin-top: 1rem;
  text-align: center;
}

#copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.5rem;
}

#copyright span {
  margin-top: 0.2rem;
  font-size: 0.6rem;
}

#footerPipe {
  display: none;
}

@media screen and (min-width: 400px) {
  #profile {
    height: 40%;
  }
}

@media screen and (min-width: 550px) {
  #profile {
    max-height: 280px;
  }

  h1 {
    top: 20px;
    left: 20px;
  }

  #menu-item {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-left: 15px;
  }

  #menu-item li {
    padding-left: 10px;
    font-size: 0.5rem;
  }

  #about-me p {
    margin: 0 5% 3vh;
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 880px) {
  body {
    width: 95%;
    margin: 0 auto;
  }

  #menu-item li {
    padding-left: 40px;
    font-size: 0.8rem;
  }

  #profile {
    margin-top: 200px;
  }
}

@media screen and (min-width: 1100px) {
  #about-me {
    padding-bottom: 60px;
    align-items: flex-start;
  }

  #work {
    margin-left: 5%;
    align-items: flex-start;
  }

  #work h2 {
    margin-bottom: 0;
    font-size: 1.4rem;
  }

  #about-me h2 {
    margin-bottom: 40px;
    margin-left: 5%;
    font-size: 1.4rem;
  }

  #about-me p {
    margin: 0 5% 5vh;
    text-align: left;
  }

  #tools {
    margin-top: 4vh;
    margin-left: 5%;
    color: black;
    font-weight: 400;
    margin-bottom: 25px;
  }

  #workCards {
    justify-content: flex-start;
  }
}
